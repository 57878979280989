<template>
  <div class="c_breadcrumb">
    <Flex justify="flex-start" :center="true" direction="row" class="c_wrapper">
      <IconSVG
        v-show="mobile"
        class="c_breadcrumb__separator_mobile"
        :stroke-color-var="globalDarkTheme ? 'gray_191' : 'black'"
        height="10"
        width="6"
        stroke-width="5px"
        handle="arrow_left"
        aria-hidden="true"
        tag="span"
      />
      <span class="c_breadcrumb__link__wrapper" v-for="(link, index) in linksFormatted" :key="index">
        <component
          :is="index !== links.length - 1 ? 'Button' : 'span'"
          class="c_breadcrumb__link"
          :class="{
            'c_breadcrumb__link--first': index === 0,
            'c_button--pointer': index < links.length - 1,
            'c_breadcrumb__link--last': index === links.length - 1,
          }"
          :key="index"
          v-if="link.label && (link.href || index === links.length - 1)"
          v-bind="{
            href: index !== links.length - 1 ? link.href : null,
            elementType: index === links.length - 1 ? 'span' : 'button',
            newWindow: link.newWindow,
            ariaLabel: link.ariaLabel,
            reset: true,
            unstyle: true,
          }"
        >
          {{ removeBR(link.label) }}
        </component>
        <span v-if="link.label && link.href && index !== links.length - 1 && !mobile" class="c_breadcrumb__separator"
          >&gt;&nbsp;</span
        >
      </span>
    </Flex>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from 'Components/button/Button.vue';
import Flex from 'Components/flex/Flex.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  components: {
    Button,
    Flex,
    IconSVG,
  },
  data() {
    return {};
  },
  props: {
    links: Array,
    inheritGlobalTheme: { type: Boolean, default: false, required: false },
  },
  computed: {
    mobile() {
      if (['sm', 'md'].includes(this.$mq)) {
        return true;
      }
      return false;
    },
    linksFormatted() {
      const breadcrumbs = [];
      if (this.links && this.links?.length) {
        if (this.mobile) {
          breadcrumbs.push(this.links[this.links.length - 2]);
          return breadcrumbs;
        }
        return this.links;
      }
      return [];
    },
    ...mapState({
      globalDarkTheme: (state) => state.global.globalDarkTheme,
    }),
  },
  methods: {
    removeBR(text) {
      return text.replace(/<br\/>|<br>|<br \/>/g, '');
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
.c_breadcrumb {
  $self: &;
  font-size: px_to_rem(14);
  color: $color_gray_86;
  background: $color_white;
  padding: px_to_rem(16) 0;

  .app--navigation-dark & {
    background: $color_black;
  }
  @at-root #{$self}__link {
    display: inline-block;
    padding-right: px_to_rem(6);
    text-decoration: none;
    letter-spacing: -0.01em;
    @include font('graphik_medium');
    &[href]:hover,
    &[href]:focus {
      color: $color_black;
      text-decoration: underline;
    }
    @at-root #{&}__wrapper {
      padding-right: px_to_rem(6);
    }
    @at-root #{&}--last {
      padding-right: 0;
      @include font('graphik_light');
    }
  }
  @at-root #{$self}__separator {
    @include font('graphik_light');
    @at-root #{&}_mobile {
      position: relative;
      bottom: px_to_rem(1);
      display: flex;
      align-items: center;
      margin-right: px_to_rem(12);
    }
  }
  .app--navigation-dark & {
    color: $color_gray_191;
    #{$self}__link {
      text-transform: none;
      &[href]:hover,
      &[href]:focus {
        color: $color_white;
      }
      #{&}--last {
        @include font('graphik_regular');
      }
    }
  }
}
</style>
