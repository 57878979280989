var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_breadcrumb"},[_c('Flex',{staticClass:"c_wrapper",attrs:{"justify":"flex-start","center":true,"direction":"row"}},[_c('IconSVG',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobile),expression:"mobile"}],staticClass:"c_breadcrumb__separator_mobile",attrs:{"stroke-color-var":_vm.globalDarkTheme ? 'gray_191' : 'black',"height":"10","width":"6","stroke-width":"5px","handle":"arrow_left","aria-hidden":"true","tag":"span"}}),_vm._v(" "),_vm._l((_vm.linksFormatted),function(link,index){return _c('span',{key:index,staticClass:"c_breadcrumb__link__wrapper"},[(link.label && (link.href || index === _vm.links.length - 1))?_c(index !== _vm.links.length - 1 ? 'Button' : 'span',_vm._b({key:index,tag:"component",staticClass:"c_breadcrumb__link",class:{
          'c_breadcrumb__link--first': index === 0,
          'c_button--pointer': index < _vm.links.length - 1,
          'c_breadcrumb__link--last': index === _vm.links.length - 1,
        }},'component',{
          href: index !== _vm.links.length - 1 ? link.href : null,
          elementType: index === _vm.links.length - 1 ? 'span' : 'button',
          newWindow: link.newWindow,
          ariaLabel: link.ariaLabel,
          reset: true,
          unstyle: true,
        },false),[_vm._v("\n        "+_vm._s(_vm.removeBR(link.label))+"\n      ")]):_vm._e(),_vm._v(" "),(link.label && link.href && index !== _vm.links.length - 1 && !_vm.mobile)?_c('span',{staticClass:"c_breadcrumb__separator"},[_vm._v("> ")]):_vm._e()],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }