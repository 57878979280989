<template>
  <section class="c_quote_slider_2022 c_agile_slider_2022 c_wrapper">
    <div :class="['c_quote_slider--' + textAlignment]">
      <RelativeAnchor :id="pageTitle" />
      <agile
        @after-change="getCurrentSlide($event)"
        :options="agileOptions"
        class="c_agile_slider_2022 c_slider--arrow_buttons"
        v-if="displaySlider"
      >
        <Quote v-for="(slide, index) in slidesFiltered" :key="index" class="slide" :slide="slide" />
      </agile>
      <SlideNumbers v-if="displaySlider" :number-of-slides="numberOfSlides" :current-slide="currentSlide" />
      <Quote v-if="!displaySlider" class="slide" :slide="slidesFiltered[0]" />
    </div>
  </section>
</template>

<script>
import { VueAgile } from 'vue-agile';
import SlideNumbers from 'Components/slider/SlideNumbers.vue';
import Quote from 'Components/2022_quote_slider/Quote.vue';

export default {
  components: {
    RelativeAnchor: () => import('Components/relative_anchor/RelativeAnchor.vue'),
    SlideNumbers,
    agile: VueAgile,
    Quote,
  },
  data() {
    return {
      numberOfSlides: null,
      currentSlide: null,
      agileOptions: {
        infinite: true,
        slidesToShow: 1,
        navButtons: true,
        fade: true,
        dots: false,
      },
      displaySlider: false,
      slidesFiltered: [],
    };
  },
  props: {
    pageTitle: String,
    slides: Array,
    textAlignment: {
      type: String,
      default: 'left',
    },
  },
  methods: {
    getCurrentSlide(e) {
      return (this.currentSlide = e.currentSlide + 1);
    },
  },
  created() {
    this.slides.forEach((slide) => {
      if (slide.enableQuote) {
        this.slidesFiltered.push(slide);
      }
    });
    this.numberOfSlides = this.slidesFiltered.length;
    if (this.numberOfSlides > 1) {
      this.displaySlider = true;
    }
  },
};
</script>

<style lang="scss">
.c_quote_slider_2022 {
  padding-top: px_to_rem(25);
  padding-bottom: px_to_rem(25);
  @include mq($mq_sm_to_md) {
    padding-top: px_to_rem(50);
    padding-bottom: px_to_rem(50);
  }
  &.c_wrapper {
    padding-left: px_to_rem(40);
    padding-right: px_to_rem(40);
    @include mq($mq_sm_to_md) {
      padding-left: px_to_rem(100);
      padding-right: px_to_rem(100);
    }
  }
  .c_quote_slider {
    $self: &;
    @at-root #{$self}--left {
      text-align: left;
    }
    @at-root #{$self}--center {
      text-align: center;
    }
    @at-root #{$self}--right {
      text-align: right;
    }

    .c_slider {
      --slider-dots-default-color: #{$color_gray_112};
    }
    .c_box--yellow & .c_slider {
      --slider-dots-default-color: #{$color_gray_112};
      --slider-dots-active-color: #{$color_white};
    }

    @at-root #{$self}__mask {
      background: rgba($color_black_raw, 0.75);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 25;
    }
  }
}
</style>
