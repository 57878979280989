<template>
  <div class="slide c_2022_related_product" v-if="product">
    <h2 class="c_2022--h2_graphik">
      <a :href="getProductHref(product.uri)">
        <span class="c_2022_related_product__title" v-if="product.relatedProductTitle">{{
          product.relatedProductTitle
        }}</span>
        <span class="c_2022_related_product__descriptor" v-if="product.relatedProductDescriptor">{{
          product.relatedProductDescriptor
        }}</span>
      </a>
    </h2>
    <p class="c_2022--body c_2022_related_product__description" v-if="product.relatedProductDescription">
      {{ product.relatedProductDescription }}
    </p>
    <div class="c_2022_related_product__image_container" v-if="product.relatedProductImage[0]">
      <MediaImage
        :sources="formatSources(product.relatedProductImage[0])"
        :alt="product.relatedProductTitle + ' ' + product.relatedProductDescriptor"
      />
    </div>
  </div>
</template>

<script>
import MediaImage from 'Components/image/MediaImage.vue';

export default {
  props: {
    product: Object,
  },
  components: {
    MediaImage,
  },
  methods: {
    formatSources(image) {
      const returnSources = [
        { srcset: image.lpImageGridMasonry?.srcWebp, type: 'webp' },
        { srcset: image.lpImageGridMasonry?.srcset },
      ];
      return returnSources;
    },
    getProductHref(productUri) {
      return this.localePath(`/${productUri}`);
    },
  },
};
</script>

<style lang="scss">
.c_2022_related_products {
  $self: &;
  .c_2022_related_product {
    flex-direction: column;
    text-align: center;
    h2 {
      margin-bottom: px_to_rem(20);
      a {
        color: $color_black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @at-root #{&}__title {
      @include font('graphik_bold');
    }
    @at-root #{&}__subtitle {
      @include font('graphik_light');
    }
    @at-root #{&}__image_container {
      margin-top: px_to_rem(30);
      picture {
        display: flex;
        width: 100%;
        height: 100%;
        img {
          object-fit: cover;
          width: 100%;
          max-height: px_to_rem(600);
        }
      }
    }
  }
}
</style>
