<template>
  <section>
    <div
      class="c_2022_product__overview c_agile_slider_2022 product__overview c_wrapper c_box--margin"
      v-if="gallerySources"
    >
      <div>
        <Flex direction="column" justify="space-between" class="product__overview__info" :key="orientationKey">
          <div>
            <h2
              class="c_2022--h2_graphik c_2022--h_b"
              v-if="data.pageTitle && data.displayTitle"
              v-html="data.pageTitle"
            ></h2>
            <p class="product__overview__subhead respect-breaks" v-if="data.productOverviewSubhead">
              {{ data.productOverviewSubhead }}
            </p>
            <div v-if="gallerySources.length > 1">
              <agile
                @after-change="showCurrentSlide($event)"
                class="c_slider c_slider__main c_slider--arrow_buttons"
                ref="main"
                :options="optionsMain"
                :as-nav-for="[$refs.thumbnails]"
              >
                <div class="slide" v-for="(slide, index) in gallerySources" :key="index">
                  <MediaImage
                    v-if="!slide.videoId"
                    :sources="slide.sourcesMain"
                    :alt="slide.alt"
                    :caption="slide.caption"
                    caption-class="c_2022--caption"
                  />
                  <div class="slide" v-else>
                    <Button
                      @click="slide.videoId ? $refs.videoBannerOverlay.show(slide) : null"
                      unstyle
                      reset
                      :aria-label="$t('Open media in popup overlay')"
                    >
                      <MediaImage :sources="slide.sourcesMain" :alt="slide.alt" />
                      <IconSVG handle="media_play_2022" />
                    </Button>
                    <figure v-if="slide.caption">
                      <figcaption class="c_2022--caption" style="margin-top: 8px !important">
                        {{ slide.caption }}
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </agile>
              <SlideNumbers :current-slide="currentSlide" :number-of-slides="numberOfSlides" />
            </div>
            <div v-else>
              <div class="c_slider c_slider__main">
                <div class="slide" v-if="!gallerySources[0].videoId">
                  <MediaImage
                    :sources="gallerySources[0].sourcesMain"
                    :alt="gallerySources[0].alt"
                    :caption="gallerySources[0].caption"
                    caption-class="c_2022--caption"
                  />
                </div>
                <div class="slide" v-else>
                  <Button
                    @click="gallerySources[0].videoId ? $refs.videoBannerOverlay.show(gallerySources[0]) : null"
                    unstyle
                    reset
                    :aria-label="$t('Open media in popup overlay')"
                    v-if="gallerySources[0].videoId"
                  >
                    <MediaImage :sources="gallerySources[0].sourcesMain" :alt="gallerySources[0].alt" />
                    <IconSVG handle="media_play_2022" />
                  </Button>
                  <figure v-if="gallerySources[0].caption">
                    <figcaption class="c_2022--caption" style="margin-top: 8px !important">
                      {{ gallerySources[0].caption }}
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
            <div class="product__overview__description respect-breaks" v-if="data.productOverviewDescription">
              <p>{{ data.productOverviewDescription }}</p>
            </div>
          </div>
        </Flex>
        <agile
          class="c_slider__thumbnails"
          @after-change="showCurrentSlide($event)"
          ref="thumbnails"
          :options="optionsThumbs"
          :as-nav-for="asNavFor2"
          v-show="data.displayThumbnailNav"
        >
          <div
            class="slide"
            v-for="(slide, index) in gallerySources"
            :key="index"
            @click="$refs.thumbnails.goTo(index)"
          >
            <MediaImage
              :sources="slide.sourcesThumbnails"
              :alt="slide.relatedProductTitle + ' ' + slide.relatedProductDescriptor"
            />
            <IconSVG handle="media_play_2022" v-if="slide.videoId" />
          </div>
        </agile>
      </div>
    </div>
    <Overlay
      class="c_2022_product__overview c_video_banner_2022__overlay"
      ref="videoBannerOverlay"
      theme="video-lightbox"
      :show-close-button="true"
    >
      <MediaVideo
        v-if="gallerySources[currentSlide - 1]?.videoId"
        loading="eager"
        :source="
          gallerySources[currentSlide - 1].videoSource ? gallerySources[currentSlide - 1].videoSource : 'youtube'
        "
        :video-id="gallerySources[currentSlide - 1].videoId"
      ></MediaVideo>
    </Overlay>
  </section>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance } from '@nuxtjs/composition-api';
import Flex from 'Components/flex/Flex.vue';
import { VueAgile } from 'vue-agile';
import MediaImage from 'Components/image/MediaImage.vue';
import SlideNumbers from 'Components/slider/SlideNumbers.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import Overlay from 'Components/overlay/Overlay.vue';
import MediaVideo from 'Components/video/MediaVideo.vue';

export default defineComponent({
  name: 'ProductOverviewGallery',
  components: {
    agile: VueAgile,
    Flex,
    MediaImage,
    SlideNumbers,
    IconSVG,
    MediaVideo,
    Overlay,
  },
  props: {
    data: Object,
    currentSlide: Number,
    parentSlideIndex: Number,
  },
  setup(props) {
    const state = reactive({
      orientationKey: 0,
      gallerySources: [],
      overviewGalleryOverlaySliderIndex: 1,
      optionsMain: {
        dots: false,
        fade: true,
        navButtons: true,
      },
      optionsThumbs: {
        centerMode: false,
        dots: false,
        navButtons: false,
        slidesToShow: 5,
        responsive: [
          {
            breakpoint: 600,
          },
          {
            breakpoint: 1000,
            settings: {
              navButtons: false,
            },
          },
        ],
      },
      asNavFor1: [],
      asNavFor2: [],
      numberOfSlides: 0,
      currentSlide: 1,
    });

    const showCurrentSlide = (e) => {
      state.currentSlide = e.currentSlide + 1;
    };

    const main = ref(null);

    const instance = getCurrentInstance();

    // Todo: Remove this if vue-agile gets an author update or if we change sliders
    const handleOrientationChange = () => {
      instance.proxy.$emit('orientation-changed', state.currentSlide);
      state.orientationKey += 1;
      setTimeout(() => {
        main.value.goTo(props.parentSlideIndex - 1);
      }, 500);
    };

    props.data.galleryAssets?.forEach((item) => {
      state.gallerySources.push({
        alt: item.title,
        sourcesMain: [
          { srcWebp: item.galleryEnlarged?.srcWebp, type: 'image/webp' },
          { srcset: item.galleryEnlarged?.srcset },
        ],
        sourcesThumbnails: [
          { srcWebp: item.galleryThumbnail?.srcWebp, type: 'image/webp' },
          { srcset: item.galleryThumbnail?.srcset },
        ],
        videoId: item.videoId || false,
        videoSource: item.videoSource || '',
        caption: item.caption || false,
      });
    });

    return {
      ...toRefs(state),
      showCurrentSlide,
      handleOrientationChange,
      main,
    };
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
    this.numberOfSlides = this.gallerySources.length;
    if (this.numberOfSlides < 5) {
      this.optionsThumbs.slidesToShow = this.numberOfSlides;
    }
    window.addEventListener('orientationchange', this.handleOrientationChange);
  },
});
</script>

<style lang="scss">
.c_2022_product__overview {
  $self: &;

  &.c_wrapper {
    padding-left: px_to_rem(40);
    padding-right: px_to_rem(40);
    @include mq($mq_sm_to_md) {
      padding-left: px_to_rem(100);
      padding-right: px_to_rem(100);
    }
  }
  h2 {
    margin-bottom: px_to_rem(10);
  }
  .c_slider__main {
    margin: 0 auto;
    .c_icon_svg {
      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: px_to_rem(47);
        height: px_to_rem(47);
        color: $color_yellow;
        @include mq($mq_sm_to_md) {
          width: px_to_rem(100);
          height: px_to_rem(100);
        }
        @include mq($mq_md_to_lg) {
          width: px_to_rem(150);
          height: px_to_rem(150);
        }
      }
    }
    .slide {
      & > button {
        border: none;
        padding: 0;
      }
      figure {
        figcaption {
          display: block;
          margin-top: px_to_rem(15);
        }
      }
    }
  }
  .c_slider__thumbnails {
    margin-top: px_to_rem(30);
    display: none;
    @include mq($mq_md_to_lg) {
      display: block;
    }
    .agile__list {
      border-top-left-radius: px_to_rem(10);
      border-bottom-left-radius: px_to_rem(10);
      border-top-right-radius: px_to_rem(10);
      border-bottom-right-radius: px_to_rem(10);
    }
    .slide {
      cursor: pointer;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: px_to_rem(0);
        width: 100%;
        height: px_to_rem(5);
        background-color: $color_gray_230;
      }
      &.agile__slide--active {
        &:after {
          background-color: $color_yellow;
        }
      }
      figure {
        max-height: px_to_rem(230);
        picture {
          display: flex;
          height: 100%;
          width: 100%;
          img {
            object-fit: cover;
            width: 100%;
            height: auto;
          }
        }
      }
      .c_icon_svg {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        svg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: px_to_rem(47);
          height: px_to_rem(47);
          color: $color_yellow;
        }
      }
    }
  }
  .agile__actions {
    .agile__nav-button {
      width: px_to_rem(33);
      height: px_to_rem(33);
      background-color: $color_gray_230;
      font-size: px_to_rem(14);
      &.agile__nav-button--prev {
        @include mq($mq_sm_to_md, '', 'rem') {
          left: calc(-50px - var(--slider-arrow-distance-offset-md, 0px));
        }
        @include mq($mq_md_to_lg, '', 'rem') {
          left: calc(-60px - var(--slider-arrow-distance-offset-lg, 0px));
        }
        @include mq(1730, '', 'rem') {
          left: calc(-80px - var(--slider-arrow-distance-offset-sm, 0px));
        }
        left: 0;
      }
      &.agile__nav-button--next {
        @include mq($mq_sm_to_md, '', 'rem') {
          right: calc(-50px - var(--slider-arrow-distance-offset-md, 0px));
        }
        @include mq($mq_md_to_lg, '', 'rem') {
          right: calc(-60px - var(--slider-arrow-distance-offset-lg, 0px));
        }
        @include mq(1730, '', 'rem') {
          right: calc(-80px - var(--slider-arrow-distance-offset-sm, 0px));
        }
      }
    }
  }
  .c_slider__slide--thumbnail .c_slider__slide--hover_effect {
    height: 100%;
  }
  .c_video video,
  .c_video iframe {
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
</style>
