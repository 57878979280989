<template>
  <div class="c_slider__slide_numbers c_2022--lead">
    <span class="c_slider__current_slide">
      {{ currentSlide }}
    </span>
    <span class="c_slider__number_divider">/</span>
    <span class="c_slider__total_slides">
      {{ numberOfSlides }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    currentSlide: Number,
    numberOfSlides: Number,
  },
};
</script>

<style lang="scss">
.c_slider {
  $slider: &;
  @at-root #{&}__slide_numbers {
    display: flex;
    padding-left: 0;
    justify-content: center;
    margin-top: px_to_rem(0);
    position: relative;
    align-self: center;
    top: px_to_rem(-35);
    @include mq($mq_sm_to_md) {
      position: static;
      margin-top: px_to_rem(40);
    }
    @at-root #{$slider}__number_divider {
      color: $color_yellow;
      padding: 0 px_to_rem(8);
    }
    @include mq($mq_sm_to_md) {
      justify-content: flex-start;
    }
  }
}
</style>
