<template>
  <section>
    <RelativeAnchor :id="data.pageTitle" />
    <ProductOverviewGallery
      @orientation-changed="getPersistedSlideIndex"
      :parent-slide-index="currentSlide"
      :data="data"
    />
  </section>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import ProductOverviewGallery from './ProductOverviewGallery.vue';

export default defineComponent({
  name: 'ProductOverview',
  components: {
    RelativeAnchor: () => import('Components/relative_anchor/RelativeAnchor.vue'),
    ProductOverviewGallery,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const state = reactive({
      currentSlide: 1,
    });

    const getPersistedSlideIndex = (localSlideInd) => {
      state.currentSlide = localSlideInd;
    };

    return {
      ...toRefs(state),
      getPersistedSlideIndex,
    };
  },
});
</script>
