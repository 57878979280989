<template>
  <section
    class="c_related_products_container"
    :class="{
      'c_related_products_container--max_width': maxWidth,
      'c_related_products_container--rounded': roundedCorners,
    }"
  >
    <div class="c_wrapper">
      <RelativeAnchor :id="data.pageTitle" v-if="data.pageTitle" />
      <div class="c_related_products__title">
        <h2 class="c_2022--body-sm">{{ data.pageTitle }}</h2>
      </div>
      <div class="c_2022_related_products c_agile_slider_2022">
        <client-only v-if="showSlider">
          <agile :options="agileOptions" @after-change="showCurrentSlide($event)">
            <RelatedProduct v-for="(product, index) in data.relatedProduct" :key="index" :product="product" />
          </agile>
          <SlideNumbers :current-slide="currentSlide" :number-of-slides="numberOfSlides" />
        </client-only>
        <client-only v-else>
          <RelatedProduct v-if="data.relatedProduct" :product="data.relatedProduct[0]" />
        </client-only>
      </div>
    </div>
  </section>
</template>

<script>
import { VueAgile } from 'vue-agile';
import SlideNumbers from 'Components/slider/SlideNumbers.vue';
import RelatedProduct from 'Components/2022_related_products/RelatedProduct.vue';

export default {
  props: {
    data: Object,
    maxWidth: { type: Boolean, default: false },
    roundedCorners: { type: Boolean, default: false },
  },
  data() {
    return {
      agileOptions: {
        infinite: true,
        slidesToShow: 1,
        navButtons: true,
        fade: true,
        dots: false,
      },
      numberOfSlides: 0,
      currentSlide: 1,
      showSlider: false,
    };
  },
  components: {
    RelativeAnchor: () => import('Components/relative_anchor/RelativeAnchor.vue'),
    agile: VueAgile,
    SlideNumbers,
    RelatedProduct,
  },
  methods: {
    showCurrentSlide(e) {
      this.currentSlide = e.currentSlide + 1;
    },
  },
  created() {
    this.numberOfSlides = this.data.relatedProduct?.length;
    if (this.numberOfSlides > 1) {
      this.showSlider = true;
    }
  },
};
</script>

<style lang="scss">
.c_related_products_container {
  background-color: $color_gray_240;
  padding-bottom: px_to_rem(40);
  .c_wrapper {
    padding-left: px_to_rem(40);
    padding-right: px_to_rem(40);
    @include mq($mq_sm_to_md) {
      padding-left: px_to_rem(100);
      padding-right: px_to_rem(100);
    }
  }
  @at-root #{&}--max_width {
    @include mq($mq_sm_to_md) {
      max-width: px_to_rem(1800);
      margin: 0 auto;
    }
  }
  @at-root #{&}--rounded {
    border-radius: px_to_rem(12);
  }
}
.c_related_products__title {
  display: flex;
  justify-content: center;
  margin-bottom: px_to_rem(25);
  @include mq($mq_sm_to_md) {
    margin-bottom: px_to_rem(50);
  }
  h2 {
    padding: px_to_rem(20) px_to_rem(50);
    background-color: $color_white;
    @include font('graphik_medium');
  }
}
</style>
